import { ICloseOrderQueryParams, IModifyOrderQueryParams, ITradeOrder } from "@/services/trade/order";
import {
    ICloseDeleteOrderAction,
    IModifyOrderAction,
    ICloseTradeRequestAction,
    ICloseTradeSubmitAction,
    IDeleteOrderConfirmAction,
    IDeleteOrderRequestAction,
    IModifyOrderCancelAction,
    IModifyOrderRequestAction,
    IModifyOrderSubmitAction,
    IOrderChangeFromSocketAction,
    ISetOrderAction,
    ISetOrdersAction,
    OrdersActionTypes,
    IRemoveClosingTicketAction,
    IReplaceClosingOrderAction
} from "@/redux/reducers/ordersReducer";

const {
    ORDER_CHANGED_SOCKET,
    SET_ORDERS,
    SET_ORDER,
    CLOSE_DELETE_ORDER,
    MODIFY_ORDER,
    DELETE_ORDER_REQUEST,
    DELETE_ORDER_CONFIRM,
    MODIFY_ORDER_REQUEST,
    MODIFY_ORDER_SUBMIT,
    MODIFY_ORDER_CANCEL,
    CLOSE_TRADE_REQUEST,
    CLOSE_TRADE_SUBMIT,
    REMOVE_CLOSING_TICKET,
    REPLACE_CLOSING_ORDER
} = OrdersActionTypes;

export type OrderOperationTypes = "Add" | "Update" | "Delete";

export interface IOrderSocketResponse {
    order: ITradeOrder;
    type: OrderOperationTypes;
}

export const ordersFetched = (response: ITradeOrder[]): ISetOrdersAction => {
    return {
        type: SET_ORDERS,
        orders: response,
    };
};

export const orderChangedViaSocket = (response: IOrderSocketResponse): IOrderChangeFromSocketAction => {
    return {
        type: ORDER_CHANGED_SOCKET,
        payload: response,
    };
};

export const setOrderAction = (response: ITradeOrder): ISetOrderAction => {
    return {
        type: SET_ORDER,
        order: response,
    };
};

export const closeDeleteOrderAction = (response: ITradeOrder): ICloseDeleteOrderAction => {
    return {
        type: CLOSE_DELETE_ORDER,
        order: response,
    };
};

export const modifyOrderAction = (response: Partial<ITradeOrder>): IModifyOrderAction => {
    return {
        type: MODIFY_ORDER,
        order: response,
    };
};

export const deleteOrderRequest = (
    order: Partial<ITradeOrder> & { seenAskPrice: number; seenBidPrice: number }
): IDeleteOrderRequestAction => {
    return {
        type: DELETE_ORDER_REQUEST,
        order,
    };
};

export const deleteOrderConfirm = ({
    order,
    args,
}: {
    order: Partial<ITradeOrder> & { seenAskPrice: number; seenBidPrice: number };
    args: any;
}): IDeleteOrderConfirmAction => {
    return {
        type: DELETE_ORDER_CONFIRM,
        order,
        args,
    };
};

export const modifyOrderRequest = (order: Partial<ITradeOrder>): IModifyOrderRequestAction => {
    return {
        type: MODIFY_ORDER_REQUEST,
        order,
    };
};

export const modifyOrderSubmit = (
    request: Partial<IModifyOrderQueryParams>
): IModifyOrderSubmitAction => {
    return {
        type: MODIFY_ORDER_SUBMIT,
        request,
    };
};

export const modifyOrderCancel = (): IModifyOrderCancelAction => {
    return {
        type: MODIFY_ORDER_CANCEL,
    };
};

export const closeTradeRequest = (
    order: Partial<ITradeOrder> & { seenAskPrice: number; seenBidPrice: number }
): ICloseTradeRequestAction => {
    return {
        type: CLOSE_TRADE_REQUEST,
        order,
    };
};

export const closeTradeSubmit = (request: Partial<ICloseOrderQueryParams>): ICloseTradeSubmitAction => {
    return {
        type: CLOSE_TRADE_SUBMIT,
        request,
    };
};

export const removeClosingTicket = (ticket: string | number): IRemoveClosingTicketAction => {
    return {
        type: REMOVE_CLOSING_TICKET,
        ticket,
    };
};

export const replaceClosingOrder = (order: ITradeOrder): IReplaceClosingOrderAction => {
    return {
        type: REPLACE_CLOSING_ORDER,
        order,
    };
};
