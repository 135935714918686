export const DATE_FORMAT = "YYYY-MM-DDTHH:mm";

export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const PLATFORM_INTRO_COOKIE_KEY = "wtIntroSkip";

export enum TRADE_OPERATION_TYPE {
    BUY = "Buy",
    SELL = "Sell",
}

export const GUEST_FAVOURITES_STORAGE_KEY = "trader_guest_favourites";

export const MARGIN_CALL_COOKIE_KEY = "trader_margin_call_shown";

export const SYMBOLS_IMAGES_MAP_STORAGE_KEY = "symbols_images_map";

export const TRANSACTIONS_TYPES = {
    regular_deposit: "Deposit",
    deposit_cc: "Deposit",
    adjustment: "Correction",
    regular_withdrawal: "Withdrawal",
    cancel_withdrawal_request: "Withdrawal Cancellation",
    chargeback: "Correction",
    cancel_chargeback: "Correction",
    rollover_correction: "Rollover Correction",
    psp_recall: "PSP Recall",
    refer_a_friend: "Refer a Friend",
    rebate: "Rebate",
    agreement: "Agreement",
    balance_correction: "Balance Correction",
    bo_correction: "Correction",
    cancel_withdrawal: "Cancel Withdrawal",
    dealing_correction: "Dealing Correction",
    fee: "Fee",
    fee_reimbursement: "Fee Reimbursement",
    fraud_correction: "Fraud correction",
    reset_balance: "Reset Balance",
    technical_error: "Technical Error",
    verification_bonus: "Verification Bonus",
    binaryclosure: "BinaryClosure",
    competition_winner: "Competition Winner",
    swap_corrections: "Swap Corrections",
    money_transfer: "Money Transfer",
};

export const DOLLARS_SYMBOLS = {
    //USD: "US$",
    CAD: "CA$",
    AUD: "AU$",
    NZD: "NZ$",
    SGD: "S$",
    HKD: "HK$",
    MXN: "MX$",
    BND: "BN$",
    FJD: "FJ$",
    TTD: "TT$",
    JMD: "J$",
    BSD: "B$",
    KYD: "CI$",
    BBD: "Bds$",
    XCD: "EC$",
    LRD: "L$",
    SRD: "SR$",
    GYD: "G$",
    NAD: "N$",
    ZWD: "Z$",
};

export const VALID_CURRENCIES = {
    AED: "د.إ",
    AFN: "؋",
    ALL: "L",
    AMD: "֏",
    ANG: "ƒ",
    AOA: "Kz",
    ARS: "$",
    AUD: "$",
    AWG: "ƒ",
    AZN: "₼",
    BAM: "KM",
    BBD: "$",
    BDT: "৳",
    BGN: "лв",
    BHD: ".د.ب",
    BIF: "FBu",
    BMD: "$",
    BND: "$",
    BOB: "Bs.",
    BRL: "R$",
    BSD: "$",
    BTN: "Nu.",
    BWP: "P",
    BYN: "Br",
    BZD: "BZ$",
    CAD: "$",
    CDF: "FC",
    CHF: "CHF",
    CLP: "$",
    CNY: "¥",
    COP: "$",
    CRC: "₡",
    CUP: "$",
    CVE: "Esc",
    CZK: "Kč",
    DJF: "Fdj",
    DKK: "kr",
    DOP: "RD$",
    DZD: "د.ج",
    EGP: "£",
    ERN: "Nfk",
    ETB: "Br",
    EUR: "€",
    FJD: "$",
    FKP: "£",
    FOK: "kr",
    GBP: "£",
    GEL: "₾",
    GGP: "£",
    GHS: "₵",
    GIP: "£",
    GMD: "D",
    GNF: "FG",
    GTQ: "Q",
    GYD: "$",
    HKD: "$",
    HNL: "L",
    HRK: "kn",
    HTG: "G",
    HUF: "Ft",
    IDR: "Rp",
    ILS: "₪",
    IMP: "£",
    INR: "₹",
    IQD: "ع.د",
    IRR: "﷼",
    ISK: "kr",
    JEP: "£",
    JMD: "J$",
    JOD: "د.ا",
    JPY: "¥",
    KES: "Sh",
    KGS: "с",
    KHR: "៛",
    KID: "$",
    KMF: "CF",
    KRW: "₩",
    KWD: "د.ك",
    KYD: "$",
    KZT: "₸",
    LAK: "₭",
    LBP: "ل.ل",
    LKR: "Rs",
    LRD: "$",
    LSL: "L",
    LYD: "ل.د",
    MAD: "د.م.",
    MDL: "L",
    MGA: "Ar",
    MKD: "ден",
    MMK: "Ks",
    MNT: "₮",
    MOP: "P",
    MRU: "UM",
    MUR: "₨",
    MVR: "Rf",
    MWK: "MK",
    MXN: "$",
    MYR: "RM",
    MZN: "MT",
    NAD: "$",
    NGN: "₦",
    NIO: "C$",
    NOK: "kr",
    NPR: "Rs",
    NZD: "$",
    OMR: "ر.ع.",
    PAB: "B/.",
    PEN: "S/",
    PGK: "K",
    PHP: "₱",
    PKR: "₨",
    PLN: "zł",
    PYG: "₲",
    QAR: "ر.ق",
    RON: "lei",
    RSD: "дин.",
    RUB: "₽",
    RWF: "FRw",
    SAR: "﷼",
    SBD: "$",
    SCR: "₨",
    SDG: "ج.س.",
    SEK: "kr",
    SGD: "$",
    SHP: "£",
    SLE: "Le",
    SLL: "Le",
    SOS: "Sh",
    SRD: "$",
    SSP: "£",
    STN: "Db",
    SYP: "£",
    SZL: "L",
    THB: "฿",
    TJS: "ЅМ",
    TMT: "T",
    TND: "د.ت",
    TOP: "T$",
    TRY: "₺",
    TTD: "$",
    TVD: "$",
    TWD: "NT$",
    TZS: "Sh",
    UAH: "₴",
    UGX: "Sh",
    USD: "$",
    UYU: "$U",
    UZS: "сўм",
    VES: "Bs.",
    VND: "₫",
    VUV: "Vt",
    WST: "T",
    XAF: "FCFA",
    XCD: "$",
    XOF: "CFA",
    XPF: "₣",
    YER: "﷼",
    ZAR: "R",
    ZMW: "ZK",
    ZWL: "Z$",
};
