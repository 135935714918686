import { isServerSide } from "@/utils/index";
import getConfig from "next/config";

export const getLocationParam = (name: string): string => {
    if (isServerSide()) {
        return null;
    }

    return new URLSearchParams(window.location.search).get(name);
};

export const isDevelopmentMode = (): boolean => {
    const { publicRuntimeConfig } = getConfig();
    const env = publicRuntimeConfig["envMode"];

    return env === "stage" || window?.location?.host?.includes("localhost");
};

export const analyticsEnabled = (): boolean => {
    // get query param "analytics" from URL
    const analyticsParam = getLocationParam("analytics");
    return analyticsParam === "1";
};
