import { callApi } from "@/services/index";
import { removeEmpty } from "@/utils/index";
import { ICommonAPIErrorResponse, ResponseContentType } from "@/services/common/api";
import { getUserTimezoneOffsetInHours } from "@/utils/dateTime";

const getOperationTimestamp = (): number => {
    const value = localStorage.getItem("operationStartTimestamp");

    return value && parseInt(value, 10);
};

export interface ITradeOrder {
    ticket?: number;
    openTime?: string;
    closeTime?: string;
    expiration?: string;
    type: TradeOrderOperationType;
    lots: number;
    symbol: string;
    openPrice?: number;
    stopLoss?: number;
    takeProfit?: number;
    closePrice?: number;
    magicNumber?: number;
    swap?: number;
    commission?: number;
    comment?: string;
    profit?: number;
    rateOpen?: number;
    rateClose?: number;
    rateMargin?: number;
    ex: IOrderExtraInfo;
    placedType?: TradeOrderPlacedType;
    operationStartTimestamp?: string;
    // calculated  on client's side values
    distance?: number;
    currentPrice?: number;
    positionId?: number;
}

interface IOrderExtraInfo {
    activation: number;
    close_price: number;
    close_time: number;
    cmd: TradeOrderCommand;
    comment: string;
    commission: number;
    commission_agent: number;
    conv_rates: string[];
    digits: number;
    expiration: number;
    login: number;
    magic: number;
    margin_rate: number;
    open_price: number;
    open_time: number;
    order: number;
    place_type: number;
    profit: number;
    reserved: string[];
    sl: number;
    spread: number;
    state: number;
    storage: number;
    symbol: string;
    taxes: number;
    timestamp: number;
    tp: number;
    value_date: number;
    volume: number;
}

export enum TradeOrderCommand {
    "Buy",
    "Sell",
    "BuyLimit",
    "SellLimit",
    "BuyStop",
    "SellStop",
    "Balance",
    "Credit",
}

export type TradeOrderPlacedType =
    | "Client"
    | "Expert"
    | "Dealer"
    | "Signal"
    | "Gateway"
    | "Mobile"
    | "Web"
    | "Api";

export type TradeOrderOperationType =
    | "Buy"
    | "Sell"
    | "BuyLimit"
    | "SellLimit"
    | "BuyStop"
    | "SellStop"
    | "Balance"
    | "Credit";

export interface ISendOrderQueryParams {
    symbol: string;
    operation: TradeOrderOperationType;
    volume: number | string;
    price?: number;
    stopLoss?: number;
    takeProfit?: number;
    comment?: string;
    magic?: number;
    expiration?: string;

    seenBidPrice?: number;
    seenAskPrice?: number;
}

export const getOrders = async (): Promise<ITradeOrder[] | null> => {
    const response = await callApi({
        endpoint: "/api/orders",
        method: "GET",
    });

    if (response && !response.isFailed) {
        return response;
    }

    return [];
};

// CHANGE THIS!
export const sendOrder = async (
    queryParams: ISendOrderQueryParams
): Promise<ITradeOrder | ICommonAPIErrorResponse> => {
    return await callApi({
        endpoint: "/api/orders",
        method: "POST",
        // @ts-ignore
        body: removeEmpty({ ...queryParams, operationStartTimestamp: new Date().getTime() }),
    });
};

export interface IModifyOrderQueryParams {
    ticket: number;
    stopLoss?: number;
    takeProfit?: number;
    volume?: number;
    price?: number;
    expiration?: string;
    isPendingOrder?: boolean;
}

export const modifyOrder = async (
    queryParams: IModifyOrderQueryParams
): Promise<ITradeOrder | ICommonAPIErrorResponse> => {
    const { ticket, ...params } = queryParams;

    return await callApi({
        endpoint: `/api/orders/${ticket}`,
        method: "PUT",
        // @ts-ignore
        body: removeEmpty({ ...params, operationStartTimestamp: new Date().getTime() }),
    });
};

export const deleteOrder = async (data: {
    ticket: number;
    args: {
        [key: string]: number;
    };
}): Promise<string | ICommonAPIErrorResponse> => {
    const response = await callApi({
        endpoint: `/api/pending-orders/${data.ticket}`,
        method: "DELETE",
        body: {
            operationStartTimestamp: new Date().getTime(),
            ...data.args,
        },
    });

    return response;
};

// MTR only requests - need to remove this comment after MTR implementation
export const sendPendingOrder = async (
    queryParams: ISendOrderQueryParams
): Promise<ITradeOrder | ICommonAPIErrorResponse> => {
    return await callApi({
        endpoint: "/api/pending-orders",
        method: "POST",
        // @ts-ignore
        body: removeEmpty({ ...queryParams, operationStartTimestamp: new Date().getTime() }),
    });
};

export const modifyPendingOrder = async (
    queryParams: IModifyOrderQueryParams
): Promise<ITradeOrder | ICommonAPIErrorResponse> => {
    const { ticket, ...params } = queryParams;

    return await callApi({
        endpoint: `/api/pending-orders/${ticket}`,
        method: "PUT",
        // @ts-ignore
        body: removeEmpty({ ...params, operationStartTimestamp: new Date().getTime() }),
    });
};

// MTR only logic ends - need to remove this comment after MTR implementation

interface IGetTradeHistoryQueryParams {
    dateFrom: string;
    dateTo: string;
}

export const getTradeHistory = async ({
    dateFrom,
    dateTo,
}: IGetTradeHistoryQueryParams): Promise<ITradeOrder[] | null> => {
    const response = await callApi({
        endpoint: "/api/trade-history",
        method: "GET",
        queryParams: {
            from: dateFrom,
            to: dateTo,
        },
    });

    if (response && !response.isFailed) {
        return response;
    }

    return [];
};

export interface IGetTradeHistoryPDFQueryParams {
    dateFrom: string;
    dateTo: string;
}

export const getTradeHistoryPDF = async ({
    dateFrom,
    dateTo,
}: IGetTradeHistoryPDFQueryParams): Promise<any[] | null> => {
    return await callApi({
        endpoint: "/api/trade-history/pdf",
        method: "GET",
        queryParams: {
            from: dateFrom,
            to: dateTo,
            tz: getUserTimezoneOffsetInHours(),
        },
        responseContentType: ResponseContentType.PDF,
    });
};

export interface ICloseOrderQueryParams {
    ticket: number;
    volume?: number | string;
    seenAskPrice?: number | string;
    seenBidPrice?: number | string;
    isMTR?: boolean;
    order?: ITradeOrder;
}

export const closeOrder = async (
    queryParams: ICloseOrderQueryParams
): Promise<ITradeOrder | ICommonAPIErrorResponse> => {
    return await callApi({
        endpoint: "/api/closed-orders",
        method: "POST",
        // @ts-ignore
        body: removeEmpty({
            ...queryParams,
            operationStartTimestamp: new Date().getTime(),
        }),
    });
};
