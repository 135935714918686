import { getSymbolQuoteValue, IQuoteInfo, quoteSocketManager } from "@/hooks/trade/tradeSocket";
import { ITradeOrderOperationCategory, PendingOrderType } from "@/contexts/TradeOrder/interfaces";
import {
    ISymbolInfo,
    SymbolMarginCalculationMode,
    SymbolProfitCalculationMode,
} from "@/services/trade/symbols";
import { isForexSymbol } from "@/hooks/symbols";
import { ITradeOrder, TradeOrderCommand } from "@/services/trade/order";
import { PriceType } from "@/interfaces/index";
import { getLocationParam } from "@/utils/url";
import { getNumberFromString } from "@/utils/format";

interface IConvertAmountToCurrencyProps {
    debug?: boolean;
    amount: number;
    fromCurrency: string;
    toCurrency: string;
    symbols: string[];
    conversionPriceType?: PriceType;
}

export const convertAmountToCurrency = (props: IConvertAmountToCurrencyProps): number => {
    const { debug, amount, toCurrency, conversionPriceType } = props;

    let symbols = props.symbols;
    symbols = symbols.map(s => s.replace("!", ""));

    if (amount === null) {
        return null;
    }

    let { fromCurrency } = props;

    fromCurrency = fromCurrency?.toUpperCase();

    if (fromCurrency === toCurrency) {
        return amount;
    }

    const conversion = getTickInternal({
        forex: {
            from: fromCurrency,
            to: toCurrency,
        },
    });

    const { bid, ask } = conversion;

    let price = (ask + bid) / 2; // as discussed, midprice is used as default

    if (conversionPriceType) {
        price = conversionPriceType === "Bid" ? bid : ask;
    }

    return price * amount;
};
const getConversionPrice = (currencyPair: string, conversionPriceType: PriceType): number => {
    const { bid, ask } = getSymbolQuoteValue(currencyPair);

    return conversionPriceType === "Bid" ? bid : ask;
};

const getCurrencyPairAssetFromSymbols = (from: string, to: string, symbols: string[]): string => {
    const defaultPair = `${from}${to}`;
    const altPair = `${from}${to}!`;

    if (symbols.includes(defaultPair)) {
        return defaultPair;
    } else if (symbols.includes(altPair)) {
        return altPair;
    } else {
        return null;
    }
};

interface IGetTradeProfitProps {
    debug?: boolean;
    openPrice: number;
    ask: number;
    bid: number;
    tradeType: ITradeOrderOperationCategory;
    lotSize: number | string;
    accountCurrency: string;
    symbolInfo: ISymbolInfo;
    symbols: string[];
}

const checkPrecision = (_num: number): number => {
    let num: string = _num.toString();

    function getPrecision(scinum) {
        let arr = [];
        // Get the exponent after 'e', make it absolute.
        arr = scinum.split("e");
        const exponent = Math.abs(arr[1]);

        // Add to it the number of digits between the '.' and the 'e'
        // to give our required precision.
        let precision = new Number(exponent);
        arr = arr[0].split(".");
        precision += arr[1].length;

        return precision;
    }

    if (num.match(/^[-+]?[1-9]\.[0-9]+e[-]?[1-9][0-9]*$/)) {
        num = (+num).toFixed(Number(getPrecision(num)));
    }
    return parseFloat(num);
};

export interface ITickOptions {
    forex?: {
        from: string;
        to: string;
    };
    symbol?: string;
}

const getTick = (options: ITickOptions): IQuoteInfo => {
    if (options?.forex) {
        return getSymbolQuoteValue(`${options.forex.from}${options.forex.to}`);
    }
    if (options?.symbol) {
        return getSymbolQuoteValue(options.symbol);
    }
};

const getTickInternal = (options: {
    forex?: {
        from: string;
        to: string;
    };
    symbol?: string;
    supportedCurrencies?: string[];
    infLoop?: {
        loop?: number;
        forceStop?: boolean;
    };
}): Partial<IQuoteInfo> => {
    if (options?.forex?.from !== undefined && options?.forex?.from === options?.forex?.to) {
        return {
            bid: 1,
            ask: 1,
        };
    }

    if (options?.infLoop?.forceStop) {
        return undefined;
    }
    const loop = options?.infLoop?.loop || 0;
    if (loop === 5) {
        options.infLoop.forceStop = true;
        return undefined;
    }
    let tick: IQuoteInfo;
    let response: Partial<IQuoteInfo>;

    if (options.forex) {
        const { from, to } = options.forex;
        tick = getTick({ forex: { from, to } });

        if (!tick?.ask) {
            tick = getTick({ forex: { from: to, to: from } });
            if (!tick?.ask) {
                response = findCommonTick(from, to);
                if (!response) {
                    console.error(`Cannot find tick for - from: ${from}, to: ${to}`);
                    return;
                }
            } else {
                response = {};
                response.bid = 1 / tick.bid;
                response.ask = 1 / tick.ask;
            }
        } else if (tick) {
            response = tick;
        }
    } else if (options.symbol) {
        if (!tick?.ask) {
            console.error(`Cannot find tick for - symbol: ${options.symbol}`);
            return;
        }
        response = tick;
    }
    return response;
};

const findCommonTick = (
    from: string,
    to: string,
    optionalCurrencies = ["USD", "EUR", "GBP", "AUD", "NZD", "JPY"]
) => {
    for (const optionalCurrencyTOMiddle of optionalCurrencies) {
        const fromToOptional = getTick({
            forex: {
                from,
                to: optionalCurrencyTOMiddle,
            },
        });

        if (!fromToOptional) {
            continue;
        }
        const optionalToTo = getTick({
            forex: {
                from: optionalCurrencyTOMiddle,
                to: to,
            },
        });
        if (optionalToTo.bid === null && optionalToTo.ask === null) {
            quoteSocketManager.subscribeToQuote({
                forex: {
                    from: optionalCurrencyTOMiddle,
                    to: to,
                },
            });
        }

        if (fromToOptional.bid === null && fromToOptional.ask === null) {
            quoteSocketManager.subscribeToQuote({
                forex: {
                    from,
                    to: optionalCurrencyTOMiddle,
                },
            });
        }

        return {
            symbol: `${from}${to}`,
            bid: checkPrecision(1 / ((1 / fromToOptional.bid) * (1 / optionalToTo.bid))),
            ask: checkPrecision(1 / ((1 / fromToOptional.ask) * (1 / optionalToTo.ask))),
        };
    }
    return undefined;
};

export const getTradeProfit = (props: IGetTradeProfitProps): number => {
    const {
        debug,
        openPrice,
        ask,
        bid,
        tradeType,
        lotSize,
        accountCurrency,
        symbolInfo,
        symbols,
    } = props;

    if (!symbolInfo || !ask || !bid) {
        return null;
    }

    const { contractSize } = symbolInfo;
    const symbolQuoteCurrency = getSymbolQuoteCurrency(symbolInfo);

    // Buy positions are opened at the ASK price and closed at the BID price.
    // Sell positions are opened at the BID price and closed at the ASK price.
    const closePrice = tradeType === "Buy" ? bid : ask;
    const priceDiff = tradeType === "Buy" ? closePrice - openPrice : openPrice - closePrice;
    const calcProfit = priceDiff * Number(lotSize) * contractSize;

    return convertAmountToCurrency({
        debug,
        amount: calcProfit,
        fromCurrency: symbolQuoteCurrency,
        toCurrency: accountCurrency,
        symbols,
        conversionPriceType: tradeType === "Buy" ? "Bid" : "Ask",
    });
};

export const getCurrentBrand = (): string => {
    const splitHostname = window.location.hostname.split(".");

    return splitHostname.length > 2 ? splitHostname[splitHostname.length - 2] : "market10";
};

/**
 * Forex symbols use different logic to detect quote currency
 */
export const getSymbolQuoteCurrency = (symbolInfo: ISymbolInfo): string => {
    const isForex = isForexSymbol(symbolInfo);
    return isForex ? getForexSymbolQuoteCurrency(symbolInfo?.id) : symbolInfo?.currency;
};

export const getForexSymbolQuoteCurrency = (symbolId: string): string => {
    return symbolId.substring(3, 6);
};

export const getPriceDiffInPipsOrPoints = (
    a: number,
    b: number,
    point: number,
    isForex = false
): number => {
    return Math.round(Math.abs(a - b) / point) / (isForex ? 10 : 1);
};

export const isInvalidPendingOrderPrice = (
    activeOperationCategory: ITradeOrderOperationCategory,
    pendingOrderType: PendingOrderType,
    pendingOrderPrice: number,
    marketPrice: number
): boolean => {
    return (
        (activeOperationCategory === "Buy" &&
            pendingOrderType === "Stop" &&
            pendingOrderPrice <= marketPrice) ||
        (activeOperationCategory === "Buy" &&
            pendingOrderType === "Limit" &&
            pendingOrderPrice >= marketPrice) ||
        (activeOperationCategory === "Sell" &&
            pendingOrderType === "Stop" &&
            pendingOrderPrice >= marketPrice) ||
        (activeOperationCategory === "Sell" &&
            pendingOrderType === "Limit" &&
            pendingOrderPrice <= marketPrice)
    );
};

export const getOpenProfit = (orders): number => {
    if (!orders.length) return 0;
    return orders.reduce((acc, order) => {
        let swap = 0;
        let profit = 0;

        if (order.type !== "Balance" && order.type !== "Credit") {
            swap = getNumberFromString(order.swap);
            profit = getNumberFromString(order.profit);
        }

        return acc + (swap + profit);
    }, 0);
};
export const isInvalidSLTP = (
    operationType: ITradeOrderOperationCategory,
    stopLoss: number,
    takeProfit: number,
    marketPrice: number
): boolean => {
    if (stopLoss === null || takeProfit === null) {
        return true;
    }

    if (stopLoss > 0) {
        if (
            (operationType === "Buy" && stopLoss >= marketPrice) ||
            (operationType === "Sell" && stopLoss <= marketPrice)
        ) {
            return true;
        }
    }

    if (takeProfit > 0) {
        if (
            (operationType === "Buy" && takeProfit <= marketPrice) ||
            (operationType === "Sell" && takeProfit >= marketPrice)
        ) {
            return true;
        }
    }

    return false;
};

/**
 * For some reason tradingPlatform uses dates like 1970-01-01T00:00:00.000Z instead of null or ""
 * @param tradingPlatformDate
 */
export const isTradePlatformNullDate = (tradingPlatformDate: string): boolean =>
    /^1970-01-01/.test(tradingPlatformDate);

export const isPendingOrder = (order: Partial<ITradeOrder>): boolean => {
    return [
        TradeOrderCommand.BuyLimit,
        TradeOrderCommand.SellLimit,
        TradeOrderCommand.BuyStop,
        TradeOrderCommand.SellStop,
    ].includes(order.ex.cmd);
};

const FUTURE_INDEX_SYMBOL_REGEX = /-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\d{2}$/;

export const isFutureIndexSymbol = (symbol: string): boolean => {
    return FUTURE_INDEX_SYMBOL_REGEX.test(symbol);
};

export const getFutureIndexSymbolBaseName = (symbol: string): string => {
    if (!isFutureIndexSymbol(symbol)) {
        return symbol;
    }

    const splitSymbolName = symbol.split("-");

    splitSymbolName.pop();

    return splitSymbolName.join("-");
};

export const findCurrentFutureSymbolName = (symbol: string, symbols: string[]): string => {
    const baseName = getFutureIndexSymbolBaseName(symbol);
    const replacement = symbols.find(s => s.indexOf(baseName) === 0);

    return replacement || null;
};

export const getMarketPriceValue = ({ order, ask, bid, type }): number => {
    const isBuy = order.type.toLowerCase().includes("buy");
    const marketPrice = type === "pending" ? (isBuy ? ask : bid) : isBuy ? bid : ask;

    return marketPrice;
};

export const getDistanceValue = ({ order, point, marketPrice }): number => {
    const isBuy = order.type.toLowerCase().includes("buy");
    const diff = isBuy ? marketPrice - order.openPrice : order.openPrice - marketPrice;
    const distanceInPips = diff / point / 10;

    return Number(distanceInPips.toFixed(1));
};

export const getRequiredMargin = (
    accountCurrency: string,
    symbolId: string,
    lotSize: number | string,
    contractSize: number,
    marketPrice: number,
    profitCalculationMode: SymbolProfitCalculationMode,
    mode: SymbolMarginCalculationMode,
    accountLeverage: number,
    symbolLeverage: number,
    percentage: number
): number => {
    if (mode === "Forex") {
        return (((Number(lotSize) * contractSize) / accountLeverage) * percentage) / 100;
    } else if (mode === "CFD") {
        let calculation = (Number(lotSize) * contractSize * marketPrice * percentage) / 100;
        if (profitCalculationMode === "Forex") {
            const symbol = extractCurrenciesFromName(symbolId);

            if (symbol && symbol.length > 1) {
                const leftCurrency = symbol[0];
                const rightCurrency = symbol[1];
                const conversionTickInternal = getTickInternal({
                    forex: {
                        from: leftCurrency,
                        to: rightCurrency,
                    },
                });
                const conversionInternal = (conversionTickInternal.ask + conversionTickInternal.bid) / 2;
                calculation = calculation / conversionInternal;
            }
        }
        return calculation;
    } else if (mode === "CFDLeverage") {
        return (((Number(lotSize) * contractSize * marketPrice) / accountLeverage) * percentage) / 100;
    }

    return null;
};

export const extractCurrenciesFromName = str => {
    const regex = /([A-Za-z]{3})([A-Za-z]{3})/;
    const match = str.match(regex);

    if (match && match.length > 2) {
        return [match[1], match[2]];
    }
    return [];
};

export const isEURegulation = (): boolean => {
    const regulation = getLocationParam("regulation");

    return !regulation || ["cysec", "hcmc"].includes(regulation);
};

/**
 * As discussed, we cannot keep expired future index symbols in API response, so we can use
 * any active future index symbol with same asset (for example, PLAT-NOV22 => PLAT-DEC22)
 * and use it to get the symbol info (point, contract size, current ask / bid, etc).
 */
export const replaceMissingFutureIndexSymbols = (orders, symbols: string[]) => {
    return orders.map(order => {
        const isMissingFutureSymbol =
            !symbols.includes(order.symbol) && isFutureIndexSymbol(order.symbol);

        if (isMissingFutureSymbol) {
            const replacement = findCurrentFutureSymbolName(order.symbol, symbols);

            if (replacement) {
                return {
                    ...order,
                    symbol: replacement,
                    ex: {
                        ...order.ex,
                        symbol: replacement,
                    },
                };
            }
        }

        return order;
    });
};
