import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
    addToFavouritesUI,
    removeFromFavouritesUI,
    setFavourites,
} from "@/redux/actions/account";
import {
    AccountActionTypes,
    IAddToFavouritesAction,
    IRemoveFromFavouritesAction,
} from "@/redux/reducers/accountReducer";
import {
    getFavourites as getFavouritesApi,
    resetFavorites as resetFavoritesApi,
    updateFavourites,
    getDefaultFavorites
} from "@/services/trade/favourites";
import { TradeInfoActionTypes } from "@/redux/reducers/tradeInfoReducer";
import {
    getAccountInfo,
    getFavourites as getFavouritesSelector,
} from "@/redux/selectors/accountSelector";
import { fetchFavourites as fetchFavouritesAction } from "@/redux/actions/account";
import { GUEST_FAVOURITES_STORAGE_KEY } from "@/constants/index";
import { setFavoritesAlertMessage } from "../actions/systemFeedback";
import { UIActionTypes } from "../reducers/uiReducer";
import { toast } from 'react-toastify';
import { i18n } from 'next-i18next';

const { SYMBOLS_DATA_LOADED } = TradeInfoActionTypes;

const {
    FETCH_FAVOURITES,
    ADD_TO_FAVOURITES,
    REMOVE_FROM_FAVOURITES,
} = AccountActionTypes;

function* fetchFavourites() {
    const { isGuest, isLazyMode } = yield select(getAccountInfo);

    if (isGuest && !isLazyMode) {
        let guestFavorites = window.sessionStorage.getItem(GUEST_FAVOURITES_STORAGE_KEY);

        if (guestFavorites) {
            try {
                guestFavorites = JSON.parse(guestFavorites);

                if (Array.isArray(guestFavorites) && guestFavorites.length) {
                    yield put(setFavourites(guestFavorites));
                }
            } catch (e) {
                /* it's ok */
            }
        }

        return;
    }

    try {
        const favouritesResponse = yield call(getFavouritesApi);

        if (favouritesResponse && !favouritesResponse?.code) {
            yield put(setFavourites(favouritesResponse));
        }
    } catch (error) {
        console.error(error);
    }
}

function* addToFavourites(action: IAddToFavouritesAction) {
    const { isGuest, isLazyMode } = yield select(getAccountInfo);
    const favourites = yield select(getFavouritesSelector);
    const { symbolId } = action;
    const isResetFavoritesAction = !symbolId;

    try {
        let updatedFavourites;

        if (isResetFavoritesAction) {
            if (isGuest && !isLazyMode) {
                updatedFavourites = yield call(getDefaultFavorites);
            } else {
                yield call(resetFavoritesApi);
                updatedFavourites = yield call(getFavouritesApi);
                yield put(setFavourites(updatedFavourites));
                return;
            }
        } else {

            if (favourites.includes(symbolId)) {
                return;
            }

            yield put(addToFavouritesUI(symbolId));

            yield put(setFavoritesAlertMessage({
                operationType: UIActionTypes.SET_ALERT_MESSAGE_FAVORITE_ADDED,
                symbolId
            }));

            updatedFavourites = [...favourites, symbolId];
        }

        if (isGuest && !isLazyMode) {
            window.sessionStorage.setItem(
                GUEST_FAVOURITES_STORAGE_KEY,
                JSON.stringify(updatedFavourites)
            );
        } else {
            yield call(updateFavourites, { symbols: updatedFavourites });
        }

        const isMobile = window.innerWidth < 768;
        if (!isMobile) {
            const message = i18n.t('common:addedToFavorites');
            toast.info(`${symbolId} ${message}`);
        }

    } catch (error) {
        console.error(error);
    }

    yield put(fetchFavouritesAction());
}

function* removeFromFavourites(action: IRemoveFromFavouritesAction) {
    const { isGuest, isLazyMode } = yield select(getAccountInfo);
    const favourites = yield select(getFavouritesSelector);
    const { symbolId } = action;

    if (!favourites.includes(symbolId)) {
        return;
    }

    yield put(removeFromFavouritesUI(symbolId));

    try {
        yield put(setFavoritesAlertMessage({
            operationType: UIActionTypes.SET_ALERT_MESSAGE_FAVORITE_REMOVED,
            symbolId,
        }));

        const updatedFavourites = favourites.filter(f => f !== symbolId);

        if (isGuest && !isLazyMode) {
            window.sessionStorage.setItem(
                GUEST_FAVOURITES_STORAGE_KEY,
                JSON.stringify(updatedFavourites)
            );
        } else {
            yield call(updateFavourites, { symbols: updatedFavourites });
        }

        const isMobile = window.innerWidth < 768;
        if (!isMobile) {
            const message = i18n.t('common:removedFromFavorites');
            toast.info(`${symbolId} ${message}`);
        }
    } catch (error) {
        console.error(error);
    }

    yield put(fetchFavouritesAction());
}

export function* accountSaga() {
    yield takeLatest(FETCH_FAVOURITES, fetchFavourites);
    yield takeLatest(SYMBOLS_DATA_LOADED, fetchFavourites);
    yield takeEvery(ADD_TO_FAVOURITES, addToFavourites);
    yield takeEvery(REMOVE_FROM_FAVOURITES, removeFromFavourites);
}
