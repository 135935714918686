import { DOLLARS_SYMBOLS, VALID_CURRENCIES } from "../constants";

export function getNumberFromString(str) {
    if (!str || (str && typeof str === "number")) {
        return str;
    }

    // Remove any non-digit characters from the string
    const numberString = str.replace(/[^0-9.-]+/g, "");

    // Convert the number string to a number type and return it
    return Number(numberString);
}

export const formatAmount = (amount: number, currency: string = "", digits?: number): string => {
    try {
        if (Number.isNaN(amount) || amount === null || amount === undefined) {
            return "---";
        }

        if (!currency || !VALID_CURRENCIES[currency]) {
            return `${amount}`;
        }

        const currencyDisplay = DOLLARS_SYMBOLS[currency] ? "code" : "narrowSymbol";

        const formattedValue = new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency,
            currencyDisplay,
            ...(digits !== undefined
                ? { minimumFractionDigits: digits, maximumFractionDigits: digits }
                : {}),
        }).format(Math.abs(amount));

        const currencySymbol = VALID_CURRENCIES[currency] || "";

        if (currencySymbol) {
            const numericValue = formattedValue.replace(/[^0-9.,-]/g, "").trim();
            return amount < 0 ? `${currencySymbol}-${numericValue}` : `${currencySymbol}${numericValue}`;
        }

        return formattedValue;
    } catch (error) {
        console.log("some error on formatting", error);
        return `${amount}`;
    }
};

/**
 * 100000 => 100,000.00
 * can be used when currency formatter cannot be applied
 */
export const formatNumber = (value: number): string => {
    if (Number.isNaN(value) || value === null) {
        return "---";
    }

    return new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
};

// converts 5 to "05"
export const padDate = (value: number): string => (value > 9 ? String(value) : `0${value}`);

/**
 * for example in case price is 1.123, digits is 6
 * result is 1.123000
 * can be used when currency formatter cannot be applied
 */
export const normalyzeDecimalLength = (price: string | number, digits: number): string => {
    price = String(price).trim();

    let decimalPart;

    if (price.includes(".")) {
        decimalPart = price.split(".")[1];
    } else if (price.includes(",")) {
        decimalPart = price.split(",")[1];
    }

    if (decimalPart && digits > decimalPart.length) {
        return `${price}${Array(digits - decimalPart.length)
            .fill(0)
            .join("")}`;
    }
    return price;
};
